<template>
    <BT-Blade-Item
        @edit="loadPolicy"
        @saved="panelV = 0"
        bladeName="purchase-order"
        :canSave="panelV == 0"
        :cardWidth="purchaseOrder != null ? '450' : '900'"
        :changeToggle="changeToggle"
        :customBladeData="customBladeData"
        navigation="purchase-orders"
        :bladesData="bladesData"
        :canEdit="purchaseOrder == null"
        :canRefresh="purchaseOrder == null"
        :hideBackButton="purchaseOrder != null"
        :hideBladeHeader="panelV > 0"
        :loadingMsg="loadingMsg"
        :onCanDelete="v => { return v != null && (v.confirmedOn == null || v.isInactive || (v.seller != null && v.seller.isManagedByAnotherAccount)); }"
        :onCanEdit="v => { return v != null && !v.isInactive; }"
        :onFinalize="purchaseOrder != null ? finalizeSave : null"
        :onGetSave="getOrderToSave"
        :onPullSuccessAsync="orderPulled"
        :onNew="newOrder"
        :refreshToggle="refreshToggle"
        :saveToggle="saveToggle"
        :small="false"
        :title="title">
        <template v-slot:bladeSettings="{ item, data }">
            <v-list-item v-if="item != null && !data.isNew">
                <BT-Print
                    width="475"
                    block
                    :item="item"
                    :itemID="item.id"
                    :onPullSuccessAsync="pullForPrint">
                    <template v-slot="{ item }">
                        <v-container v-if="item != null">
                            <v-row>
                                <v-col cols="6">
                                    <v-list dense>
                                        <v-list-item dense>
                                            <v-list-item-avatar size="100">
                                                <v-img :src="companyLogoURL(item.invoice.sellerID)">
                                                    <template v-slot:placeholder>
                                                        <v-icon color="primary" >mdi-account</v-icon>
                                                    </template>
                                                </v-img>
                                            </v-list-item-avatar>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                                <v-list-item-title>{{ item.invoice.invoiceNumber }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                                <v-list-item-title>{{ item.invoice.customerOrderNumber }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                                <v-list-item-title>{{ item.invoice.purchaseOrderNumber }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="6">
                                    <v-list dense>
                                        <v-list-item v-if="item.delivery != null" dense>
                                            <v-list-item-content>
                                                <v-list-item-title>Delivered To</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <BT-Entity
                                                        navigation="public-locations"
                                                        single
                                                        :itemValue="item.delivery.destinationLocationID"
                                                        textFilter="toLocationLine"
                                                        useLocalCache />
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-title>Issued On</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.invoice.issuedOn | toShortDate }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Due On: {{ item.invoice.dueOn | toShortDate }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                
                            </v-row>
                        </v-container>
                    </template>
                </BT-Print>
            </v-list-item>
        </template>
        <template v-slot="{ item, data, save }">
            <v-row v-if="data.isNew" no-gutters>
                <v-tabs-items v-model="panelV" style="width: 100%;" touchless>
                    <v-tab-item />
                    <v-tab-item>
                        <BT-List-Endless
                            @change="agrID => { selectPolicy(agrID, item); }"
                            v-model="item.supplyAgreementID"
                            :canSearch="false"
                            canSearchLocal
                            itemText="seller.companyName"
                            itemValue="id"
                            label="Suppliers"
                            navigation="suppliers"
                            :onFilter="list => { return list ? list.orderBy(x => x.seller.companyName) : list; }"
                            :onPullSuccessAsync="list => { return pullSuppliers(list, item); }"
                            :searchProps="['seller.companyName']"
                            width="900">
                            <template v-slot="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="companyLogoURL(item.seller.id)" class="mx-auto">
                                        <template v-slot:placeholder>
                                            <v-icon color="primary">mdi-account</v-icon>
                                        </template>
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle :class="item.isSuspended ? 'error--text' : null">
                                        {{ item.seller.companyName }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="item.isSuspended" class="error--text">Suspended</span>
                                </v-list-item-action>
                            </template>
                        </BT-List-Endless>
                    </v-tab-item>
                    <v-tab-item>
                        <BT-Order-Items
                            @back="stepBack"
                            @next="stepForward"
                            :order="item"
                            label="Order Items"
                            :policy="currentPolicy"
                            :resetToggle="refreshOrderToggle"
                            showImages
                            useCategories />
                    </v-tab-item>
                    <v-tab-item>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar dense class="mb-4">
                                    <v-btn large @click="stepBack" icon>
                                        <v-icon large>mdi-arrow-left</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>New Order</v-toolbar-title>
                                    <v-spacer />
                                    <v-btn text @click="save" class="primary">Save</v-btn>
                                </v-toolbar>
                            </v-col>
                            <v-col cols="12">
                                <v-list-item v-if="item != null && item.seller != null" dense>
                                    <v-list-item-avatar>
                                        <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                                            <template v-slot:placeholder>
                                                <v-icon color="primary" >mdi-account</v-icon>
                                            </template>
                                        </v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                </v-list-item>
                            </v-col>
                            <v-col v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'" cols="12">
                                <BT-Select-List-Box
                                    v-model="item.dueDate"
                                    :canSearch="false"
                                    isEditing
                                    :isSelecting="item.dueDate == null"
                                    :items="currentPolicy.orderSlots"
                                    itemText="deliverBy"
                                    itemValue="deliverBy"
                                    label="Delivery Slot"
                                    :onCanSelect="v => { return v != null && v.purchaseOrderID == null }"
                                    textFilter="toDayShortDate"
                                    width="100%">
                                    <template v-slot="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.deliverBy | toDayShortDate }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="item.orderBy != null" class="grey--text">
                                                {{ item.orderBy | toTimeSpan }} till cut-off time
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text>
                                                <BT-Entity
                                                    v-if="item.purchaseOrderID != null"
                                                    itemText="customerOrderNumber"
                                                    :itemValue="item.purchaseOrderID"
                                                    navigation="purchase-orders"
                                                    single>
                                                    <template v-slot:prepend>#</template>
                                                    <!-- <template v-slot:alternate="{ item }">
                                                        {{ item != null ? 'Order Placed' : null }}
                                                    </template> -->
                                                </BT-Entity>
                                            </v-list-item-action-text>
                                            <span v-if="item.purchaseOrderID != null">Order Exists
                                                <v-btn small icon target="_blank" :to="{ name: 'purchase-order', params: { id: item.purchaseOrderID }}" title="Open in new tab">
                                                    <v-icon small>mdi-open-in-new</v-icon>
                                                </v-btn>
                                            </span>
                                        </v-list-item-action>
                                    </template>
                                </BT-Select-List-Box>
                            </v-col>
                            <v-col v-else cols="12" sm="6">
                                <BT-Date-Picker
                                    v-model="item.dueDate"
                                    :dateRules="dateRules"
                                    :dateFrom="dateFrom"
                                    hideHeader
                                    label="Delivery Date" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <BT-Field-Address
                                    v-if="isArrayOfLength(possibleLocations, 1)"
                                    label="TO"
                                    :value="item.location" />
                                <BT-Select-List-Box
                                    v-else
                                    v-model="item.locationID"
                                    isEditing
                                    :isSelecting="item.locationID == null"
                                    :items="possibleLocations"
                                    itemValue="id"
                                    label="Destination"
                                    textFilter="toLocationLine">
                                    <template v-slot="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                            <v-list-item-subtitle class="grey--text">{{ item | toLocationLine }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </BT-Select-List-Box>
                            </v-col>
                            <v-col cols="12">
                                <BT-Field-String
                                    label="Purchase Order #"
                                    v-model="item.purchaseOrderNumber"
                                    isEditing />
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-2" />
                                <v-subheader>Order Items <v-spacer />
                                    <BT-Btn
                                        @click="stepBack"
                                        icon="mdi-pencil" />
                                </v-subheader>
                                <BT-Table
                                    :canExportCSV="false"
                                    :canRefresh="false"
                                    :canSelect="false"
                                    dense
                                    :headers="[
                                        { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                                        { text: 'Description', value: 'description' },
                                        { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency', prefix: '@', suffix: 'ea' },
                                        { text: 'Total', value: 'total', display: true, align: 'end' }]"
                                    hideActions
                                    :items="item.orderItems">
                                    <template v-slot:listItem="{ item }">
                                        <v-list-item-avatar>
                                            <v-img :src="productLogoURL(item.productID)" class="my-auto">
                                                <template v-slot:placeholder>
                                                    <v-icon v-if="item.productID != null" class="primary--text" size="40">mdi-cube-outline</v-icon>
                                                </template>
                                            </v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.description }}</v-list-item-title>
                                            <v-list-item-subtitle>Qty: {{ item.quantity | toDisplayNumber }}   @{{ item.unitPrice | toCurrency }}ea</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="item.unitPrice > 0 || item.quantity > 0">
                                            {{ (item.unitPrice * item.quantity) | toCurrency }}
                                        </v-list-item-action>
                                    </template>
                                    <template v-slot:total="{ item }">
                                        <span align="right">
                                        {{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                                    </template>
                                </BT-Table>
                            </v-col>
                            <v-col class="text-right" cols="12">
                                <p class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</p>
                                <p class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</p>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-1" />
                            </v-col>
                            <v-col cols="12">
                                <h3 class="text-right mx-3 mt-2 mb-4">Total: {{ item.amountTotal | toCurrency }}</h3>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-row>
            <v-row v-else no-gutters>
                <v-col cols="12">
                    <v-list-item v-if="item != null && item.seller != null" dense>
                        <v-list-item-avatar>
                            <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                                <template v-slot:placeholder>
                                    <v-icon color="primary" >mdi-account</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else-if="currentPolicy != null" dense>
                        <v-list-item-avatar>
                            <v-img :src="companyLogoURL(currentPolicy.sellerID )" class="mx-auto">
                                <template v-slot:placeholder>
                                    <v-icon color="primary" >mdi-account</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ currentPolicy.sellerName }}</v-list-item-title>
                    </v-list-item>
                </v-col>

                <v-col 
                    v-if="data.isEditing && currentPolicy != null && (item.confirmedOn == null || item.seller.isManagedByAnotherAccount)"
                    cols="12">
                    <BT-Select-List-Box
                        v-model="item.locationID"
                        isEditing
                        :isSelecting="item.locationID == null"
                        :items="possibleLocations"
                        itemValue="id"
                        label="Destination"
                        textFilter="toLocationLine">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                <v-list-item-subtitle class="grey--text">{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Select-List-Box>
                </v-col>
                <v-col v-else cols="6" sm="4">
                    <BT-Field-Address
                        label="TO"
                        :value="item.location" />
                </v-col>

                <v-col
                    v-if="currentPolicy != null && data.isEditing && item.confirmedOn == null && currentPolicy.orderingArrangement === 'Slotted'"
                    cols="12">
                    <BT-Select-List-Box
                        v-model="item.dueDate"
                        :canSearch="false"
                        height="400"
                        :isEditing="(data.isEditing && item.confirmedOn == null) || data.isNew"
                        :isSelecting="item.dueDate == null"
                        :items="currentPolicy.orderSlots"
                        itemText="deliverBy"
                        itemValue="deliverBy"
                        label="Delivery Slot"
                        :onCanSelect="v => { return v != null && v.purchaseOrderID == null }"
                        textFilter="toDayShortDate"
                        width="200">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.deliverBy | toDayShortDate }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.orderBy != null" class="grey--text">
                                    {{ item.orderBy | toTimeSpan }} till cut-off time
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <BT-Entity
                                        v-if="item.purchaseOrderID != null"
                                        itemText="customerOrderNumber"
                                        :itemValue="item.purchaseOrderID"
                                        navigation="purchase-orders"
                                        single>
                                        <template v-slot:prepend>#</template>
                                        <!-- <template v-slot:alternate="{ item }">
                                            {{ item != null ? 'Order Placed' : null }}
                                        </template> -->
                                    </BT-Entity>
                                </v-list-item-action-text>
                                <span v-if="item.purchaseOrderID != null">Order Exists
                                    <v-btn small icon target="_blank" :to="{ name: 'purchase-order', params: { id: item.purchaseOrderID }}" title="Open in new tab">
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </span>
                            </v-list-item-action>
                        </template>
                    </BT-Select-List-Box>
                    <!-- <BT-Field-Date
                        v-else-if="data.isNew && currentPolicy != null"
                        :dateRules="dateRules"
                        v-model="item.dueDate"
                        label="Delivery Date"
                        :dateFrom="dateFrom"
                        :isEditing="data.isEditing || data.isNew" />
                    <BT-Field-Date
                        v-else
                        v-model="item.dueDate"
                        label="Due On"
                        :isEditing="data.isEditing && (item.seller.isManagedByAnotherAccount || item.confirmedOn == null)" /> -->
                </v-col>
                <v-col v-else-if="currentPolicy != null && data.isEditing && (item.confirmedOn == null || item.seller.isManagedByAnotherAccount)" cols="12" sm="4">
                    <BT-Field-Date
                        :dateFrom="dateFrom"
                        :dateRules="dateRules"
                        :isEditing="item.confirmedOn == null || item.seller.isManagedByAnotherAccount"
                        label="Delivery Date"
                        v-model="item.dueDate" />
                </v-col>
                <v-col v-else cols="6">
                    <BT-Field-Date
                        label="Delivery Date"
                        v-model="item.dueDate" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        label="Purchase Order #"
                        v-model="item.purchaseOrderNumber"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String 
                        label="Customer Order #"
                        v-model="item.customerOrderNumber" />
                </v-col>

                <v-col v-if="!data.isEditing" cols="6" sm="4" class="d-flex align-center">
                    <span v-if="item.isInactive" class="error--text text-subtitle-2 ml-4">**DELETED**</span>
                    <span v-else class="warning--text text-body-2 ml-4">{{ item.isRequestPickup ? '*Pickup' : '*Delivery' }}</span>
                </v-col>

                
                <v-col v-if="!data.isEditing && !item.isInactive" cols="6" sm="4" class="d-flex align-center">
                    <BT-Menu
                        bottom
                        buttonClass="ml-4"
                        :getOnOpenAsync="getProgress"
                        hideHeader
                        icon="mdi-progress-question"
                        isButton
                        :item="item"
                        text="Status"
                        title="Status">
                        <template v-slot="{ item }">
                            <v-list-item v-for="(progress, ind) in item" :key="ind">
                                <v-list-item-avatar>
                                    <v-icon :class="progress.isTrue ? 'success--text' : 'error--text'">mdi-progress-check</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ progress.title }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="progress.dateOn != null">{{ progress.dateOn | toLongDateAndTime }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>{{ progress.subtitle }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="progress.location != null">{{ progress.location | toLocationLine }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu>
                </v-col>

                <v-col v-if="!item.isInactive" cols="12">
                    <v-divider class="my-1" />
                    <v-subheader>Line Items
                        <v-spacer />
                        <v-btn
                            v-if="(data.isEditing && (item.confirmedOn == null || item.seller.isManagedByAnotherAccount )) || data.isNew"
                            class="my-1 mr-1"
                            @click.stop="mDrawer = !mDrawer">
                            Edit Products
                        </v-btn>
                    </v-subheader>

                    <BT-Table
                        v-if="isLengthyArray(item.orderItems)"
                        :canExportCSV="false"
                        :canRefresh="false"
                        :canSelect="false"
                        dense
                        :headers="[
                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber' },
                            { text: 'Description', value: 'description' },
                            { text: 'Unit Price', value: 'unitPrice', textFilter: 'toCurrency', prefix: '@', suffix: 'ea' },
                            { text: 'Total', value: 'total', display: true, align: 'end' }]"
                        hideActions
                        :items="item.orderItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item-avatar>
                                <v-img :src="productLogoURL(item.productID)" class="my-auto">
                                    <template v-slot:placeholder>
                                        <v-icon v-if="item.productID != null" class="primary--text" size="40">mdi-cube-outline</v-icon>
                                    </template>
                                </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.description }}</v-list-item-title>
                                <v-list-item-subtitle>Qty: {{ item.quantity | toDisplayNumber }} @{{ item.unitPrice | toCurrency }}ea</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.unitPrice > 0 || item.quantity > 0">
                                {{ (item.unitPrice * item.quantity) | toCurrency }}
                            </v-list-item-action>
                        </template>
                        <template v-slot:total="{ item }">
                            <span align="right">
                            {{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                        </template>
                    </BT-Table>
                    <BT-Btn
                        v-if="data.isNew || data.isEditing"
                        block
                        @click="mDrawer = !mDrawer"
                        icon="mdi-cube-outline"
                        label="Select Products" />
                </v-col>

                <v-col v-if="!item.isInactive" cols="12">
                    <div v-if="isLengthyArray(item.orderItems)" class="mb-2">
                        <p class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</p>
                        <p class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</p>
                        
                        <v-divider />
                        
                        <h3 class="text-right mx-3 mt-2">Total: {{ item.amountTotal | toCurrency }}</h3>
                    </div>
                </v-col>
            </v-row>

            <BT-Btn-Row v-if="!data.isNew">
                <template v-slot:right>
                    <BT-Btn
                        v-if="!data.isNew && item.isDispatched"
                        bladeName="stock-import"
                        icon="mdi-clipboard-outline"
                        :id="item.id"
                        label="Stock Import"
                        nav />

                </template>
            </BT-Btn-Row>

            <BT-Snack v-model="msg" />
        </template>
        <template v-slot:page="{ item, data, bladeHeight }">
            <BT-Order-Items-Drawer
                v-if="data.isEditing || data.isNew"
                :drawerToggle="mDrawer"
                :height="bladeHeight"
                :isEditing="(data.isEditing && (item.confirmedOn == null || item.seller.isManagedByAnotherAccount)) || data.isNew"
                :order="item"
                label="Order Items"
                :policy="currentPolicy"
                showImages
                @ok="x => { updateOrder(x, false); }"
                @okAndSave="x => { updateOrder(x, true); }"
                useCategories />
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { recalculateOrder } from '~helpers';

export default {
    name: 'Purchase-Order-Blade',
    components: {
        // BTDatePickerInput: () => import('~components/BT-Date-Picker-Input.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTOrderItemsDrawer: () => import('~components/BT-Order-Items-Drawer.vue'),
        BTOrderItems: () => import('~components/BT-Order-Items.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            changeToggle: false,
            currentOrder: null,
            currentPolicy: null,
            dueDateOptions: [],
            dateFrom: null,  
            dateRules: null,
            loadingMsg: null,
            mAgreementID: null,
            mDrawer: false,
            msg: null,
            panelV: 0,
            refreshOrderToggle: false,
            saveToggle: false,
            showMore: true
        }
    },
    props: {
        bladesData: null,
        purchaseOrder: null,
        refreshToggle: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '900'
        }
    },
    computed: {
        customBladeData() {
            if (this.purchaseOrder == null) {
                return null;
            }
            else {
                return {
                    data: {
                        id: 'new',
                        agreementID: this.purchaseOrder.supplyAgreementID,
                    }
                }
            }
        },
        possibleLocations() {
            return this.currentPolicy != null ? this.currentPolicy.possibleLocations : [];
        },
        title() {
            if (this.panelV == 1) {
                return 'Suppliers';
            }
            else if (this.panelV == 2) {
                return 'Products';
            }
            else {
                return 'Purchase Order';
            }
        }
    },
    methods: {
        finalizeSave(order) {
            this.$emit('saved', order);
        },
        getOrderToSave(bladeData, order) {
            if (order.id == null) {
                //is new so remove all auto order items
                order.orderItems = order.orderItems.filter(x => x.productID != null);
            }
            else {
                //is editing existing so keep all order items (?)
            }

            return order;
        },
        stepBack() {
            this.panelV -= 1;
        },
        stepForward() {
            this.panelV += 1;
        },
        async getProgress(order) {
            this.loadingMsg = 'Getting Progress';
            var progressList = []; //{ location, dateOn, title, subtitle }
            
            progressList.push({
                dateOn: order.createdOn,
                isTrue: order.createdOn != null,
                location: null,
                subtitle: null,
                title: 'Created',
            });

            progressList.push({
                dateOn: order.confirmedOn,
                isTrue: order.confirmedOn != null,
                location: null,
                subtitle: order.confirmedOn == null ? 'Pending' : null,
                title: 'Confirmed',
            });

            if (order.confirmedOn != null) {
                progressList.push({
                    dateOn: null,
                    isTrue: order.isDispatched,
                    location: null,
                    subtitle: order.isDispatched ? 'Done' : 'Pending',
                    title: 'Processed',
                });
                
                if (order.isDispatched) {
                    //search for courier consignment
                    try {
                        var releaseRes = await this.$BlitzIt.api.get('customer-consignments', '/Get/Progress/' + order.id);
                        if (releaseRes != null && this.isLengthyArray(releaseRes.data.data)) {
                            releaseRes.data.data.forEach(progress => {
                                var e = {
                                    dateOn: progress.releasedOn || progress.releaseDueOn,
                                    isTrue: progress.releasedOn != null,
                                    location: progress.location,
                                    subtitle: null,
                                    title: 'Released'
                                };

                                if (progress.releasedOn != null && progress.deliveredOn == null) {
                                    e.title = 'On The Road!';
                                }

                                progressList.push(e);

                                progressList.push({
                                    dateOn: progress.deliveredOn || progress.deliveryDueOn,
                                    isTrue: progress.deliveredOn != null,
                                    location: progress.location,
                                    subtitle: null,
                                    title: 'Delivered'
                                });
                            });
                        }
                    }
                    catch {
                        console.log('finding progress failed');
                    }
                }

                if (progressList.length == 3) {
                    progressList.push({
                        dateOn: null,
                        isTrue: false,
                        location: null,
                        subtitle: '...',
                        title: 'Planning Courier'
                    })
                }
            }

            

            this.loadingMsg = null;
            return progressList;
        },
        async loadPolicy(bladeData, order) {
            console.log('loading policy');
            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', order.supplyAgreementID, null, null, '/get/Policy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                if (policy.orderingArrangement === 'Slotted') {
                    for (var i = 0; i < policy.orderSlots.length; i++) {
                        var slot = policy.orderSlots[i];
                        if (!slot.purchaseOrderID) {
                            this.dueDateOptions.unshift(slot.deliverBy);
                        }
                    }
                    
                    this.dueDateOptions.sort();
                }
                else if (policy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }

                this.currentPolicy = policy;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async selectPolicy(agrID, order) {
            if (agrID == null) {
                this.msg = 'No supplier selected';
                return;
            }

            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/get/Policy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                order.isBuyerCourier = policy.isRequirePickup ? true : policy.isPreferPickup,
                order.locationID = policy.defaultLocationID;
                order.location = policy.defaultLocation;
                order.orderItems = [];
                order.subTotal = 0;
                order.taxTotal = 0;
                order.amountTotal = 0;
                order.supplyAgreementID = agrID;

                if (policy.orderingArrangement === 'Slotted') {
                    for (var i = 0; i < policy.orderSlots.length; i++) {
                        var slot = policy.orderSlots[i];
                        if (!slot.purchaseOrderID) {
                            this.dueDateOptions.unshift(slot.deliverBy);
                        }
                    }
                    
                    this.dueDateOptions.sort();
                }
                else if (policy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }

                this.currentPolicy = policy;

                this.panelV = 2;

                this.refreshOrderToggle = !this.refreshOrderToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async newOrder(bladeData, order) {
            //get policy
            console.log('newing order');
            try {
                var blankOrder = null;
                var agrID = null;

                if (this.purchaseOrder != null) {
                    this.panelV = 3;

                    agrID = this.purchaseOrder.supplyAgreementID;
                    blankOrder = this.purchaseOrder;
                    order = this.purchaseOrder;
                }
                else {
                    this.panelV = 1;
                    
                    agrID = bladeData.data?.agreementID;
                    blankOrder = {
                        isBuyerCourier: false,
                        locationID: null,
                        location: null,
                        orderItems: [],
                        supplyAgreementID: null,
                        dueDate: null,
                    };
                }

                this.loadingMsg = 'New Order';

                //is there a supplyAgreementID?
                // var agrID = bladeData.data?.agreementID;

                if (agrID == null) {
                    return blankOrder;
                }
                else {
                    var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/get/Policy');
                    var policy = resData.data.data;
                    if (policy == null) {
                        return blankOrder;
                    }

                    if (this.purchaseOrder == null) {
                        order = {
                            isBuyerCourier: policy.isRequirePickup ? true : policy.isPreferPickup,
                            locationID: policy.defaultLocationID,
                            location: policy.defaultLocation,
                            orderItems: [],
                            supplyAgreementID: agrID,
                            dueDate: null,
                        };

                        this.panelV = 1;
                    }
                    else {
                        order.isBuyerCourier = policy.isRequirePickup ? true : policy.isPreferPickup;
                    }

                    var repeatLastOrder = bladeData.data?.repeatLast;
                    var standingOrderID = bladeData.data?.standingOrderID;

                    if (repeatLastOrder) {
                        //find last order
                        var lastOrderRes = await this.$BlitzIt.store.getAll('purchase-orders', { agreementID: agrID, takeAmount: 1, includeDetails: true });
                        if (this.isLengthyArray(lastOrderRes)) {
                            var lastOrder = lastOrderRes[0];
                            lastOrder.orderItems.forEach(o => {
                                if (o.productID != null && policy.orderItems.some(z => z.productID == o.productID)) {
                                    order.orderItems.push(this.copyDeep(o));
                                }
                            });

                            order.locationID = lastOrder.locationID;
                            order.location = lastOrder.location;
                            order.isBuyerCourier = lastOrder.isBuyerCourier;
                        }
                        else {
                            var lastInvRes = await this.$BlitzIt.store.getAll('customer-invoices', { agreementID: agrID, sortBy: 'IssuedOn', takeAmount: 1, includeDetails: true });
                            if (this.isLengthyArray(lastInvRes)) {
                                var lastInvoice = lastInvRes[0];
                                lastInvoice.lineItems.forEach(l => {
                                    var policyItem = policy.orderItems.find(z => z.productID == l.itemID);
                                    if (policyItem != null) {
                                        order.orderItems.push({
                                            id: null,
                                            rowVersion: null,
                                            lineItemCode: null,
                                            description: l.description,
                                            productID: l.itemID,
                                            quantity: l.onOrder,
                                            unitPrice: l.unitPrice,
                                            discount: 0,
                                            sortNumber: l.sortNumber,
                                            tags: l.tags,
                                            taxType: l.taxType,
                                            isPlaceholder: false,
                                            placeholderProductID: null
                                        })
                                    }
                                })
                            }
                        }

                        this.panelV = 3;
                    }
                    else if (standingOrderID != null) {
                        //copy standing order
                        var standingOrder = await this.$BlitzIt.store.get('standing-purchase-orders', standingOrderID);
                        if (standingOrder != null) {
                            order.locationID = standingOrder.locationID;
                            order.location = standingOrder.location;
                            order.isBuyerCourier = standingOrder.isBuyerCourier;

                            standingOrder.orderItems.forEach(o => {
                                var policyItem = policy.orderItems.find(z => z.productID == o.productID);
                                if (policyItem != null) {
                                    order.orderItems.push({
                                        id: null,
                                        rowVersion: null,
                                        lineItemCode: null,
                                        description: o.product.productName,
                                        productID: o.productID,
                                        quantity: o.quantity,
                                        unitPrice: 0,
                                        discount: 0,
                                        sortNumber: policyItem.product.sortNumber,
                                        tags: policyItem.product.tags,
                                        taxType: policyItem.product.taxType,
                                        isPlaceholder: false,
                                        placeholderProductID: null
                                    })
                                }
                            });

                            this.panelV = 3;
                        }
                    }

                    order.dueDate = bladeData.data?.dueDate;

                    if (policy.orderingArrangement === 'Slotted') {
                        for (var i = 0; i < policy.orderSlots.length; i++) {
                            var slot = policy.orderSlots[i];
                            if (!slot.purchaseOrderID) {
                                this.dueDateOptions.unshift(slot.deliverBy);
                            }
                        }
                      
                        this.dueDateOptions.sort();
                    }
                    else if (policy.requireLeadTime) {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }
                    else {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }

                    this.currentPolicy = policy;

                    order = recalculateOrder(order, policy);

                    return order;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async orderPulled(order) {
            if (order != null && this.isLengthyArray(order.orderItems)) {
                order.orderItems = order.orderItems.orderBy('sortNumber');
            }

            this.currentOrder = order;

            return order;
        },
        async pullForPrint(item, id) {
            return {
                invoice: item,
                delivery: null,
                id: id
            }
        },
        async pullSuppliers(list, order) {
            //move on if only 1 supplier
            if (this.isArrayOfLength(list, 1)) {
                await this.selectPolicy(list[0].id, order);
            }

            return list;
        },
        cOrder(order) {
            if (this.currentPolicy != null && order != null) {
                console.log('recalculating');
                this.updateOrder(recalculateOrder(order, this.currentPolicy), false);
            }
        },
        updateOrder(order, doSave) {
            this.currentOrder.orderItems = order.orderItems;
            this.currentOrder.taxTotal = order.taxTotal;
            this.currentOrder.subTotal = order.subTotal;
            this.currentOrder.amountTotal = order.amountTotal;

            this.$emit('updateOrder', order);

            if (doSave) {
                this.saveToggle = !this.saveToggle;
            }
        },
    }
}
</script>